<template>
  <!-- <div
    class="login-container"
    :style="`background: url(${
      projectInfo.upload_domain + projectInfo.applic_bg
    }) no-repeat top center`"
  > -->
  <div class="login-container">
    <div class="main-container">
      <div class="desc-container">
        <span class="title">
          {{ projectInfo.title }}
        </span>
        <p class="desc">{{ projectInfo.desc }}</p>
      </div>
      <div class="right-container">
        <div class="login-content-container">
          <div class="top-container">
            <img src="~@/assets/img/yc-logo.png" />
          </div>
          <div class="middle-container">
            <div class="login-title">{{ projectInfo.largeTitle }}</div>
            <div class="form">
              <el-form ref="ruleFormRef" :model="form" :rules="rules">
                <el-form-item prop="account">
                  <el-input
                    v-model="form.account"
                    autocomplete="off"
                    placeholder="请输入用户手机号"
                    clearable
                  />
                  <i class="iconfont icon-a-zu2828"></i>
                </el-form-item>
                <el-form-item prop="pass">
                  <el-input
                    v-model="form.pass"
                    type="password"
                    placeholder="请输入账户密码"
                    autocomplete="off"
                    clearable
                  />
                  <i class="iconfont icon-a-zu2829"></i>
                </el-form-item>
              </el-form>
            </div>
            <el-button type="primary" @click="handleLogin"> 登录 </el-button>
          </div>

          <div class="bottom-container">{{ projectInfo.support }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import cookie from "@/utils/cookie";
import API from "@/plugins/api.js";

export default {
  setup() {
    const router = useRouter();
    onMounted(() => {
      handleVerify(false);
    });
    const rules = {
      account: [
        {
          required: true,
          message: "请输入用户手机号",
          trigger: "blur",
        },
      ],
      pass: [
        {
          required: true,
          message: "请输入账户密码",
          trigger: "blur",
        },
      ],
    };
    const formDataRef = ref({
      account: "",
      pass: "",
    });
    const projectInfo = ref({
      upload_domain: "http://vpn.ygddzy.cn:16033",
      applic_bg: "/storage/baseinfo/login_bg_a.png",
      logo: "/storage/baseinfo/logo-title.png",
      title: "云驰物业",
      desc: "贴心呵护，阳光一生",
      largeTitle: "云驰物业管理系统",
      support: "由云驰科技提供技术支持",
      captcha_appid: "2031319060",
    });
    const ruleFormRef = ref();

    function handleSubmit(res) {
      if (res.ret === 0) {
        const params = {
          username: formDataRef.value.account,
          password: formDataRef.value.pass,
          captcha_appid: res.appid,
          captcha_ticket: res.ticket,
          captcha_randstr: res.randstr,
        };
        API.login(params).then((res) => {
          cookie.setCookie("token", res.token);
          router.push("/");
        });

        // 调登录接口
      }
    }
    const captcha = ref(null);

    function handleVerify(flag) {
      nextTick(() => {
        try {
          captcha.value = new window.TencentCaptcha(
            projectInfo.value.captcha_appid,
            handleSubmit
          );
          captcha.value.destroy();
        } catch (error) {
          console.log(error);
        }
        if (flag) {
          captcha.value.show(); // 显示验证码
        }
      });
    }

    function handleLogin() {
      if (ruleFormRef.value) {
        ruleFormRef.value.validate((valid) => {
          if (valid) {
            if (captcha.value) {
              // 显示007腾讯防水墙
              captcha.value.show();
            } else {
              handleVerify(true);
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    }

    return {
      projectInfo,
      rules,
      form: formDataRef,
      ruleFormRef,
      handleLogin,
    };
  },
};
</script>

<style lang="scss">
.login-container {
  width: 100%;
  height: 100vh;
  background: url(~@/assets/img/login-bg.png) no-repeat top center;
  background-size: cover !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .main-container {
    width: 940px;
    height: 574px;
    background: url(~@/assets/img/login-form-bg.png);
    background-size: cover;
    background-color: #fff;
    box-shadow: 0px 10px 80px 0px rgba(0, 72, 32, 0.1);
    border-radius: 10px;
    position: relative;

    .desc-container {
      position: absolute;
      top: 80px;
      left: 50px;
      color: #fff;

      .title {
        font-size: 22px;
      }

      .desc {
        font-size: 16px;
        margin-top: 30px;
      }
    }

    .right-container {
      width: 80%;
      height: 100%;
      position: absolute;
      right: 0;

      .login-content-container {
        width: 470px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px 0 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .top-container {
          width: 100%;
          display: flex;
          justify-content: space-between;

          img {
            width: 84px;
            margin-top: 10px;
          }
        }

        .middle-container {
          height: 310px;
          width: 300px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .login-title {
            font-size: 26px;
            color: #333333;
          }

          .form {
            width: 100%;

            .el-input__inner {
              border: 1px solid transparent;
              border-bottom: 1px solid #ebebeb !important;
              border-radius: 0 !important;
              padding: 0 30px 0 0;
              background-color: #fff !important;
            }
          }

          .el-button {
            width: 100%;
            background-color: #2988f6;
            box-shadow: 0px 5px 5px 0px rgba(0, 114, 246, 0.3);
            border-radius: 23px;
          }

          .el-input__suffix {
            right: 20px;
          }

          .iconfont {
            position: absolute;
            right: 0;
            color: #2988f6;
            font-size: 24px;
            text-shadow: 0px 2px 0px rgba(86, 104, 146, 0.3);
          }
        }
      }
    }

    .bottom-container {
      color: #999999;
      opacity: 0.45;
      font-size: 13px;
    }
  }
}
</style>
